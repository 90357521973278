<template>
  <!-- {{ itemType }} {{ noActivitiesFound }} {{ isLoading }} {{ items }} -->
  <div
    v-if="itemType !== 'activity' || (itemType === 'activity' && !noActivitiesFound)"
    class="w-full md:px-0 mt-5 md:mt-5"
  >
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-5xl md:ml-0 pl-2 md:pl-0">{{ title }}</h2>
      <div v-if="showButtons" class="flex space-x-2 md:mr-0">
        <button
          @click="scrollLeft"
          :disabled="atStart"
          class=" bg-white hover:bg-gray-100 dark:bg-zinc-800 dark:hover:bg-zinc-700 p-2.5 rounded-full border border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md transition-all duration-300 focus:outline-none hover:ring-2 hover:ring-blue-500 dark:hover:ring-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400 disabled:opacity-50 disabled:cursor-not-allowed disabled:ring-0"
          :class="{ 'arrow-button': $device.isMobile }"
          title="Scroll Left"
          aria-label="Scroll Left"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-700 dark:text-gray-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button
          @click="scrollRight"
          :disabled="atEnd"
          class=" bg-white hover:bg-gray-100 dark:bg-zinc-800 dark:hover:bg-zinc-700 p-2.5 rounded-full border border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md transition-all duration-300 focus:outline-none hover:ring-2 hover:ring-blue-500 dark:hover:ring-blue-400 focus:ring-blue-500 dark:focus:ring-blue-400 disabled:opacity-50 disabled:cursor-not-allowed disabled:ring-0"
          :class="{ 'arrow-button': $device.isMobile }"
          title="Scroll Right"
          aria-label="Scroll Right"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-700 dark:text-gray-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
    <div class="relative">
      <div class="overflow-x-auto whitespace-nowrap no-scrollbar pb-5 px-2" ref="scrollContainer">
        <ul v-if="isLoading && props.itemType === 'activity'" class="space-x-2">
          <li v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="inline-block">
            <SkeletonLoader class="w-[210px] h-[300px] md:w-[280px] md:h-[310px]" />
          </li>
        </ul>
        <ul class="space-x-1 md:space-x-2">
          <li v-for="(item, index) in items" :key="`${encodedTitle}-${index}`" class="inline-block">
            <!-- Item type is either the itemType from props or the item-type -->
            <template v-if="(itemType ?? item.type) === 'activity'" class="inline-block">
              <ActivityCard v-if="!isLoading" :activity="item" />
              <SkeletonLoader v-else />
            </template>
            <template v-else-if="(itemType ?? item.type) === 'identity-A'">
              <IdentityCard :identity="item" :lazyLoadImg="lazyLoadImg" />
            </template>
            <template v-else-if="(itemType ?? item.type) === 'identity-B'">
              <IdentityCardV2 :identity="item" />
            </template>
            <template v-else-if="(itemType ?? item.type) === 'category'">
              <CategoryCard :category="item" />
            </template>
            <template v-else-if="(itemType ?? item.type) === 'teamMember'">
              <TeamMemberCard :teamMember="item" />
            </template>
            <template v-else>
              <CityPoster :poster="item" />
            </template>
          </li>
        </ul>
      </div>
      <NuxtLinkLocale v-if="URL" :to="URL" class="flex justify-center mt-10">
        <button
          class="flex items-center space-x-2 bg-gradient-to-r from-ts_blue_to to-ts_blue_from text-white px-4 py-2 rounded-full"
        >
          <span>{{ buttonLabel }}</span>
        </button>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import Utility from '~/server/utils/utils';
import ActivityCard from './ActivityCard.vue';
import CategoryCard from './CategoryCard.vue';
import CityPoster from './CityPoster.vue';
const log = useLogger();

const { locale } = useI18n();
const lang = computed(() => locale.value.split('-')[0]);

const props = defineProps({
  cardWidth: {
    type: Number,
    required: false,
    default: 300, // Default card width
  },
  cardHeight: {
    type: Number,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  items: {
    type: Array,
    required: false,
  },
  itemType: {
    type: String,
    required: true,
  },
  showButtons: {
    type: Boolean,
    default: true,
  },
  searchParams: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  lazyLoadImg: {
    type: Boolean,
    default: true,
  },
  URL: {
    type: String,
    required: false,
  },
  buttonLabel: {
    type: String,
    required: false,
  },
});

const scrollContainer = ref(null);
const isLoading = ref(true);
const items = props.itemType != 'activity' ? ref(props.items) : ref([]);
const route = useRoute();
const noActivitiesFound = ref(false);

const atStart = ref(true);
const atEnd = ref(false);

const encodedTitle = computed(() => {
  if (props.title) {
    return encodeURIComponent(props.title.toLowerCase().replace(/\s+/g, '-'));
  } else {
    //Return random number if title is not provided
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
});

const fetchActivities = async () => {
  if (props.itemType === 'activity') {
    const urlParams = Utility.scomposeURL(route.path).result;

    isLoading.value = true;
    try {
      const params = {
        text_search: props.searchParams.text_search || '',
        city_name: urlParams.s,
        filter_ID: props.searchParams.filterID,
        role: props.searchParams.role,
        lang: lang.value,
        page: 0,
        limit: 10,
        tags: props.searchParams.tags,
        date: null,
        exclude: [],
        sort_by: 'suggested-DESC',
        return_facets: false,
      };
      const data = await $fetch('/api/search', { params });
      items.value = data.items;
      noActivitiesFound.value = data.items.length == 0;
      isLoading.value = false;
    } catch (error) {
      noActivitiesFound.value = true;
      isLoading.value = false;
    } finally {
      isLoading.value = false;
    }
  }
};

const updateScrollState = () => {
  if (scrollContainer.value) {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;
    atStart.value = scrollLeft === 0;
    atEnd.value = scrollLeft + clientWidth + 50 >= scrollWidth;
  }
};

const scrollLeft = () => {
  if (scrollContainer.value) {
    const scrollAmount = props.cardWidth * 3;
    scrollContainer.value.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  }
};

const scrollRight = () => {
  if (scrollContainer.value) {
    const scrollAmount = props.cardWidth * 3;
    scrollContainer.value.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }
};

watch(
  () => props.items,
  (newItems) => {
    items.value = newItems;
    isLoading.value = false;
  },
  { deep: true },
);

onMounted(() => {
  if ((props.items?.length > 0) & (props.itemType !== 'activity')) {
    isLoading.value = false;
  } else {
    fetchActivities();
  }

  if (scrollContainer.value) {
    updateScrollState();
    scrollContainer.value.addEventListener('scroll', updateScrollState);
  }
});

onUnmounted(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', updateScrollState);
  }
});
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide the arrows when the user has a touchscreen */
.arrow-button {
  display: none;
}

@media (hover: hover) {
  .arrow-button {
    display: block;
  }
}
</style>
