<template>
  <div class="dark:bg-transparent dark:hover:bg-zinc-800 rounded-xl border-gray-300 shadow">
    <div @click="openProduct(activity)" class="cursor-pointer">
      <div
        class="w-[210px] h-[300px] md:w-[280px] md:h-[310px] border border-gray-200 dark:border-zinc-600 overflow-hidden rounded-xl"
      >
        <div class="">
          <SmartImg
            class="w-full h-36 object-cover rounded bg-gray-300 dark:bg-gray-600"
            :src="activity.thumb"
            sizes="(max-width: 767px) 210px, 280px"
          ></SmartImg>
        </div>
        <div class="px-2 md:p-2 md:px-2 justify-end">
          <h3
            class="text-md md:text-lg font-medium text-zinc-900 dark:text-white whitespace-normal break-words line-clamp-2 md:line-clamp-3 h-12 md:h-[75px] leading-6 overflow-hidden mt-2 md:mt-0"
          >
            {{ activity.name }}
          </h3>
          <div v-if="activity.reviews_count" class="flex items-center mt-2 md:mt-0">
            <StarRating
              :reviews_count="activity.reviews_count"
              :filled_stars="filledStars"
              :has_half_star="hasHalfStar"
              :rating="activity.reviews_avg"
            />
          </div>
          <div class="flex justify-between mt-7 md:mt-1 items-end">
            <div v-if="parseInt(duration.split(' ')[0]) > 0" class="flex items-center space-x-2 text-sm mt-0 md:mt-2">
              <img
                src="/img/icons/schedule.svg"
                class="dark:filter dark:invert dark:invert-1"
                style="font-size: 1.2rem"
              />
              <span>{{ duration }}</span>
            </div>
            <div v-if="activityPrice > 0" class="flex items-end justify-end space-x-2">
              <div class="text-zinc-600 dark:text-zinc-400 hidden md:block">{{ $t('From') }}</div>
              <div class="text-2xl font-semibold text-zinc-900 dark:text-white">
                {{ localizedNumber(activityPrice, 'currency', { maximumFractionDigits: 0 }) }}
              </div>
            </div>
            <div v-else class="text-2xl font-semibold text-zinc-900 dark:text-white leading-tight">
              {{ $t('Free') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import SmartImg from '~/components/SmartImg.vue';
import { formatHours, openProduct } from './utils';
const { t } = useI18n();

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const duration = computed(() => formatHours(props.activity.duration, t));

const activityPrice = computed(() => {
  if (props.activity.price) {
    return props.activity.price / 100;
  }
  return 0;
});

const filledStars = computed(() => Math.floor(props.activity.reviews_avg));
const hasHalfStar = computed(() => props.activity.reviews_avg - filledStars.value >= 0.5);
</script>

<style>
.material-icons {
  font-family: 'Material Icons';
  font-size: 24px;
  line-height: 1;
  vertical-align: middle;
}
</style>
